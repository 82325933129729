import React from "react";
import "../../App.css";
import BiltyProfitLoss from "../BiltyProfitLoss";

function BiltyProfitAndLoss({ sessionObject }) {
  if(!sessionObject.sessionVariables["modules"].includes("bilty-pl-report")){
    return;}
  return (
    <div className="page-inward">
      <BiltyProfitLoss sessionObject={sessionObject} />
    </div>
  );
}

export default BiltyProfitAndLoss;
