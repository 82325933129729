import React, { useEffect, useRef, useState } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import "./report.css";
import { useReactToPrint } from 'react-to-print';
import {Link} from "react-router-dom";
import {
    dataObject,
    validate,
    consignorBillingTableHeader,
    consignorBillingTableItems,
  } from "../config/ConsignorBilling.js";
  import useForm from "./useForm";
  import { consignorBillingApiConfig } from "../config/apiConfig.js";
  import { SERVER_URL,SERVER_URL_2, USE_OVERLAY } from "../config/config.js";
  import LoadingOverlay from "react-loading-overlay";
  import Popup from "reactjs-popup";
//   import {ids} from "../components/BillReport.js"

// Let's add a fetchData method to our Table component that will be used to fetch
// new data when pagination state changes
// We can also add a loading state to let our table know it's loading new data
function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length;

    return (
        <input
            value={filterValue || ""}
            className="form-control"
            onChange={(e) => {
                setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
            }}
            placeholder={`Search ${count} records...`}
        />
    );
}

const ReportTable = React.forwardRef(({
    columns,
    data,
    onSort,
    fetchData,
    dateObject,
    loading,
    pageCount: controlledPageCount,
    canView,
    handleViewClick,
    checkbox,
    handleCheckboxChange,
    checkedList,
    isChecked,
    getSelectAllValue,
    handleSelectAll,
    handleDeselectAll,
    title,
}, ref) => {
    const companyId = JSON.parse(sessionStorage.getItem("company_id"))?.company_id ?? "1";
    const fYear_fetch = JSON.parse(sessionStorage.getItem("financial_year_for_fetch")).financial_year_for_fetch;
    
    const [checked,setChecked] = useState(false);     // checkbox for in transit
    const [checkedExtended,setCheckedExtended] = useState(false);
    const [extendedTimes, setExtendedTimes] = useState(15);

    let initialCustomFilter = {}
    if(title != "Company Master Report"){
        initialCustomFilter = { companyId: "" + companyId,
        fyear: fYear_fetch,  
    }
}

    const [customFilters, setCustomFilters] = React.useState(initialCustomFilter);

    let [updatedCustomFilters, setUpdatedCustomFilters] = React.useState({
        companyId: "" + companyId,
        fyear: fYear_fetch,  
        "in_transit": null, 
    });

    React.useEffect(()=> {
        if(title == "EwbExtensionReport") {
            setCustomFilters(customFilters => ({
                ...customFilters,
                ...updatedCustomFilters
            }));
        }
    },[])

    const [highlightRow, setHighlightRow] = React.useState([]);
    const [updateStatus, setUpdateStatus] = useState("");

    const filterTypes = React.useMemo(
        () => ({
            // Add a new fuzzyTextFilterFn filter type.
            // Or, override the default text filter to use
            // "startWith"
            text: (rows, id, filterValue) => {
                return rows.filter((row) => {
                    const rowValue = row.values[id];
                    return rowValue !== undefined
                        ? String(rowValue)
                            .toLowerCase()
                            .startsWith(String(filterValue).toLowerCase())
                        : true;
                });
            },
        }),
        []
    );
    const defaultColumn = React.useMemo(
        () => ({
            // Let's set up our default Filter UI
            Filter: DefaultColumnFilter,
        }),
        []
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        // Get the state from the instance
        state: { pageIndex, pageSize, sortBy,hiddenColumns },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
            defaultColumn, // Pass our hoisted table state
            manualPagination: true, // Tell the usePagination
            // hook that we'll handle our own data fetching
            // This means we'll also have to provide our own
            // pageCount.
            manualSortBy: true,
            autoResetPage: false,
            autoResetSortBy: false,
            pageCount: controlledPageCount,
            initialState: { pageSize: 100 }
            //   autoResetFilters: false
        },
        // useFilters,
        useSortBy,
        usePagination
    );
    //   console.log("FILEIET", filters);
    // Listen for changes in pagination and use the state to fetch our new data
    React.useEffect(() => {
        fetchData({ pageIndex, pageSize, sortBy, customFilters, dateObject });
    }, [fetchData, pageIndex, pageSize, sortBy, customFilters, dateObject]);


    // *********CODE FOR TRANSIT BUTTON*******
    const handleTransit = () => {
        // let sortBy = [{"in_transit": 1}];
        // console.log("SORT", sort);
        setChecked(!checked);
        if(checked == false) {
            customFilters.in_transit = 1;
            fetchData({ pageIndex, pageSize, sortBy, customFilters, dateObject });
        }
        else {
            customFilters.in_transit = null;
            fetchData({ pageIndex, pageSize, sortBy, customFilters, dateObject });
        }
    }
    const handleTransitExtendedTimes = () => {
        setCheckedExtended(!checkedExtended);
        if(checkedExtended == false) {
            customFilters.greater_than_extended_times = extendedTimes;
            fetchData({ pageIndex, pageSize, sortBy, customFilters, dateObject });
        }
        else {
            if ('greater_than_extended_times' in customFilters) {
                delete customFilters.greater_than_extended_times;
              }
            fetchData({ pageIndex, pageSize, sortBy, customFilters, dateObject });
        }
    }
    // ******END************

    const updateFilters = (e) => {
        let { name, value } = e.target;
        value = typeof value === 'string' ? value.toLowerCase() : value.toString().toLowerCase();
        gotoPage(0)
        setCustomFilters({ ...customFilters, [name]: value });
    };

    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const [tableWidth, setTableWidth] = useState('1000px');

    // React.useEffect(() => {
    //     console.log(highlightRow);
    // })

    const tooglehighlightRow = (rowId) => {
        // console.log(rowId);
        let hlRow = [...highlightRow];
        const idx = hlRow.indexOf(rowId);
        if(idx > -1) {
            hlRow.splice(idx, 1);
            setHighlightRow(hlRow);
        }
        else {
            hlRow.push(rowId);
            setHighlightRow(hlRow);
        }
    }

    useEffect(() => {
        console.log("!!!!!!!!");

        var wrapper1 = document.getElementById('wrapper1');
        var wrapper2 = document.getElementById('wrapper2');

        wrapper1.onscroll = function () {
            wrapper2.scrollLeft = wrapper1.scrollLeft;
        };
        wrapper2.onscroll = function () {
            wrapper1.scrollLeft = wrapper2.scrollLeft;
        };

        let tableWidthCurr = componentRef.current.offsetWidth + "px";
        // console.log({ tableWidthCurr });

        setTableWidth(tableWidthCurr);
    }, [])

    const [selectedBill,setSelectedBill] = useState({bill_id:"",party:""});
    // console.log(selectedBill)

    const [selectedRows, setSelectedRows] = useState({bill_id:"",consignor_id:""});
    const [allSelect,setAllSelect] = useState(false)

    useEffect(() => {
        console.log("Selected Rows:", selectedRows);
        console.log("djbfidnijnvin",sessionStorage)
      }, [selectedRows]);

    useEffect(() => {
        console.log("Selected Rows:", selectedBill);
      }, [selectedBill]);

    const handleCheckbox = () => {
        if (allSelect) {
            // deselectAll();
            setSelectedRows({consignor_id:"",bill_id:"",});
            // console.log("checkingggggggggggg",selectedRows)
            
        } else {
            // selectAll();
            const allRowIds = data.map((row) => row.bill_no); 
            const allParty = data.map((row)=>row.consignor_id)
            console.log("hdjdsbvbvsj",allParty)
            setSelectedRows({bill_id:allRowIds,consignor_id:allParty});
            // console.log("checkingggggggggggg",ids)
          }
      
          setAllSelect(!allSelect);
    };

    
    const CustomDropdown = ({ name, value, onChange }) => {
        const handleDropdownChange = (e) => {
          const selectedValue = parseInt(e.target.value);
          onChange({ target: { name, value: selectedValue } });
        };
      
        return (
          <select
            name={name}
            value={value}
            className="form-control"
            onChange={handleDropdownChange}
          >
            <option value={-1}>Search Record</option>
            <option value={1}>Linked</option>
            <option value={2}>Company</option>
            <option value={3}>Invalid PAN</option>
            <option value={0}>Unlinked</option>
          </select>
        );
      };
    
      const updateBiltyRate = async () => {
        if(selectedRows.bill_id !== "" || selectedRows.consignor_id !== ""){
            myForm.setOverlay(true);
            // var temp1 = []
            // temp1.push(...selectedRows)
            // var temp2 = []
            // temp2.push(...selectedBill)
        
            const url = SERVER_URL_2 + "/bilty/update_rate_multi";
            const bilty_info = {
                date_from: new Date(myForm.pageState.update_date_from).toISOString(),
                date_to: new Date(myForm.pageState.update_date_to).toISOString(),
                fyear:fYear_fetch,
                branch_id:JSON.parse(sessionStorage.getItem("branch_id")).branch_id,
                // bill_nos:selectedRows.length>0? selectedRows:selectedBill,
                // consignor_id: myForm.pageState.consignor_id,
                bills:selectedRows.bill_id.length>0?selectedRows.bill_id.map((bill_id,index)=>({
                    bill_id,
                    consignor_id:selectedRows.consignor_id[index]
                })):selectedBill
            };
    
           console.log("checkinggkkgkgjgkgkgk",myForm.pageState)
           console.log( Array.isArray(selectedRows))
           console.log(typeof(temp1))
    
            let resp = await fetch(url, { 
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify(bilty_info),
            })
        
            if(resp.ok) {
                const details = await resp.json();
                if("flag" in details && details.flag == "1") {
                    setUpdateStatus("Updation Successful!");
                    // myForm.setOverlay(true)
                    window.location.reload()
                }
                else {
                    setUpdateStatus(details.detail);
                }
            }
          
              myForm.setOverlay(false);
        }
          else {
                setUpdateStatus("Please Select Atleast One Bill")
            }
       
    }

    const contentStyle = {
        maxWidth: "600px",
        width: "90%",
    };
    
    const myForm = useForm(
        "CONSIGNORBILLING",
        validate,
        { ...dataObject },
        consignorBillingApiConfig
    );

    {console.log("njffffffffff",sessionStorage.getItem("branch_id").slice(13,sessionStorage.getItem("branch_id").length-1))}
    




      const handleRowCheckBox = (rowId,rowName) => {
        // const billNo = row.cells[0].value;

    
        // const isSelected = selectedBill.includes(billNo);
    
        // const updatedSelectedBill = isSelected
        //   ? selectedBill.filter((selected) => selected !== billNo)
        //   : [...selectedBill, billNo];
    
        // setSelectedBill(updatedSelectedBill);
    //     console.log("helooooooooooo",allSelect);
    //    return allSelect

            const isSelected = selectedRows.bill_id.includes(rowId);

            if (isSelected) {
                // If the checkbox is selected, remove it from selectedRows
                setSelectedRows(prevSelectedRows => ({...prevSelectedRows,bill_id:prevSelectedRows.bill_id.filter(id => id !== rowId),consignor_id:
                prevSelectedRows.consignor_id.filter(temp=>temp !== rowName)}));
            } else {
                // If the checkbox is not selected, add it to selectedRows
                setSelectedRows(prevSelectedRows => ({
                    ...prevSelectedRows,
                    bill_id: [...prevSelectedRows.bill_id, rowId],
                    consignor_id:[...prevSelectedRows.consignor_id,rowName]
                }));
            }
      };


    const checkDisabledCondition = (fieldInfo) => {
        if (fieldInfo.name == "consignor_gst") {
            return "disabled";
        } else if (fieldInfo.name == "extra_hamali") {
            return "disabled";
        } else if (fieldInfo.name == "door_del_charge") {
            return "disabled";
        } else if (fieldInfo.name == "extra_percentage_amount") {
            return "disabled";
        } else if (fieldInfo.name == "bilty_charge") {
            return "disabled";
        } else if (fieldInfo.name == "gross_amount") {
            return "disabled";
        } else if (fieldInfo.name == "x") {
            return "disabled";
        } else if (fieldInfo.name == "total_amount") {
            return "disabled";
        } else if (myForm.pageMode == "view") {
            return "disabled";
        } else if (myForm.pageMode == "edit" && fieldInfo.name == "party_name") {
            return "disabled";
        } else if (myForm.pageMode == "edit" && fieldInfo.name == "input_date") {
            // return "disabled";
        } else {
            return "";
        }
    };
    
   
   
    


    // Render the UI for your table
    return (
        <>
        {
        USE_OVERLAY && (
            <LoadingOverlay
                active={myForm.overlay}
                spinner
                text="Loading your content..."
                styles={{
                    wrapper: {
                        // width: '400px',
                        // height: '400px',
                        // position:'relative',
                        // top:0,
                        // marginTop:'280px !important',
                        // position: 'relative',
                        // display:'flex',
                        overflow: true ? "hidden" : "scroll",
                    },
                    content:{
                        margin: "280px auto",
                    }
                 
                }}
            ></LoadingOverlay>
        )
    }
                 {title=="FreightBillRegister"?<div className="form-title-b">      
                     <div className="form-row-m"  style={{paddingTop: "10px"}}>
                            <label className="form-label">Date From:</label>
                            <input
                                className="form-input-mr-statement-date"
                                type="date"
                                name="update_date_from"
                                placeholder=""
                                value={myForm.pageState.update_date_from}
                                onChange={myForm.handleChange}
                                ref={(a) => myForm.storeInputReferenceForSelect(a, "update_date_from")}
                                disabled={checkDisabledCondition({ name: "update_date_from" })}
                                onKeyPress={(a) => {
                                    console.log("Here");
                                    if (a.key == "Enter") {
                                        myForm.makeFocusOnParticularField("update_date_to");
                                    }
                                }}
                            />
                    </div>
                    <div className="form-row-m"  style={{paddingTop: "10px"}}>
                        <label className="form-label">Date To:</label>
                        <input
                            className="form-input-mr-statement-date"
                            type="date"
                            name="update_date_to"
                            placeholder=""
                            value={myForm.pageState.update_date_to}
                            onChange={myForm.handleChange}
                            ref={(a) => myForm.storeInputReferenceForSelect(a, "update_date_to")}
                            disabled={checkDisabledCondition({ name: "update_date_to" })}
                            onKeyPress={(a) => {
                                console.log("Here");
                                if (a.key == "Enter") {
                                    myForm.makeFocusOnParticularField("update_button");
                                }
                            }}
                        />
                    </div> 
                    <button
                        onClick={updateBiltyRate}
                        type="button"
                        style={{marginTop: "1px" }}
                        // className="btn btn-primary"
                        // ref={(a) => myForm.storeInputReferenceForSelect(a, "update_button")}
                    >
                        Update
                    </button>
                    
                </div>:<div></div>} 
                 <div>
                <Popup
                    open={updateStatus != ""}
                    modal
                    contentStyle={contentStyle}
                    closeOnDocumentClick={false}
                >
                    {(close) => {
                        return (
                            <div className="pop-up-container">
                                <div className="pop-up-header">
                                    Status of Update
                                    <div>
                                        <a className="pop-up-close btn" onClick={() => {
                                            setUpdateStatus("");
                                            close();
                                        }}>
                                            &times;
                                        </a>
                                    </div>
                                </div>
                                <div className="popup-content">
                                    <div className="pop-up-fields">
                                        <div className="pop-up-field">
                                            <label className="">Message : </label>
                                            <div className="">
                                                {updateStatus}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="pop-up-actions">
                                    <button
                                        className="pop-up-button"
                                        onClick={() => {
                                            setUpdateStatus("");
                                            close();
                                        }}
                                    >
                                        Okay
                                    </button>
                                </div>
                            </div>
                        );
                    }}
                </Popup>
            </div> 
            {/* <button onClick={handleSendData}>Api Call</button> */}
            <div className="report-container">

            {/* CODE FOR TRANSIT CHECKBOX */}
            {title == "EwbExtensionReport" ? 
            <div className="spaceBetw">
            <label>
                <input 
                    type="checkbox"
                    defaultChecked={checked} 
                    onChange={handleTransit}
                /> 
                In Transit ?
            </label>
            <div>
            <label>
                <input 
                    type="checkbox"
                    defaultChecked={checkedExtended} 
                    onChange={handleTransitExtendedTimes}
                    /> 
                Extended Times&nbsp;&gt;&#61;&nbsp;
            </label>
            <input
            size="1"
            type="text"
            value={extendedTimes}
            onChange={(e) => setExtendedTimes(e.target.value)}
            onKeyDown={(a) => {
                if (a.key == "Enter") {
                    customFilters.greater_than_extended_times = parseInt(extendedTimes);
                    fetchData({ pageIndex, pageSize, sortBy, customFilters, dateObject });
                }
            }}
            />
            </div>
            </div>
            : 
            <div></div> }

                <div id="wrapper1">
                    <div className="div1" style={{ width: `${tableWidth}` }}>
                    </div>
                </div>
                <div id="wrapper2" >
                    <table {...getTableProps()} className="div2" ref={componentRef}>
                        <thead >
                            
                            {headerGroups.map((headerGroup) => (
                                <tr {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        
                                        title == "vehicle" ? <input 
                                            type="checkbox"
                                            name="main_checkbox"
                                            value={getSelectAllValue()}
                                            onChange={(e) => {
                                                if(getSelectAllValue()) {
                                                    handleDeselectAll();
                                                }
                                                else {
                                                    handleSelectAll();
                                                }
                                            }}
                                        /> : <input
                                            type="checkbox"
                                            // checked={selectAll}
                                            onChange={handleCheckbox}
                                        />
                                    }
                                    {
                                        canView && <div></div>
                                    }
                                    {
                                        title != "vehicle" && checkbox && <input 
                                            type="checkbox"
                                            name="main_checkbox"
                                            value={getSelectAllValue()}
                                            onChange={(e) => {
                                                if(getSelectAllValue()) {
                                                    handleDeselectAll();
                                                }
                                                else {
                                                    handleSelectAll();
                                                }
                                            }}
                                        />
                                    }
                                    {headerGroup.headers.map((column) => (
                                        <th {...column.getHeaderProps({
                                            style: {
                                                minWidth: column.minWidth,
                                                width: column.width,
                                                whiteSpace: "nowrap",
                                                display: column.Header=='Consignor_id'? 'none':'table-cell'
                                            },
                                        })}>

                                            <span {...column.getSortByToggleProps({
                                                style: {
                                                    minWidth: column.minWidth,
                                                    width: column.width,
                                                },
                                            })}>
                                                {column.render("Header")}
                                                {column.isSorted
                                                    ? column.isSortedDesc
                                                        ? " 🔽"
                                                        : " 🔼"
                                                    : ""}
                                            </span>
                                            <div>
                                                {column.accessor && column.canFilter ? <input
                                                    name={column.id}
                                                    value={customFilters[column.id]}
                                                    className="form-control"
                                                    onChange={updateFilters}
                                                    placeholder={`Search records...`}
                                                    style={{ width: column.width, minWidth: column.minWidth }}
                                                /> : null}

                                            </div>
                                            <div>
                                            {column.accessor && column.canDropDown &&
                                                <CustomDropdown
                                                    name={column.id}
                                                    value={customFilters[column.id]}
                                                    onChange={updateFilters}
                                                    style={{ width: column.width, minWidth: column.minWidth }}
                                                />
                                            }
                                            </div>

                                            {/* <div>{column.canFilter ? column.render("Filter") : null}</div> */}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody {...getTableBodyProps()}>
                            {(() => {
                                const userLoginTracker = {};

                                return page.map((row, i) => {
                                    
                                    prepareRow(row);
                                    const currentRowId = row.cells[0].value;
                                    const highlightCurrentRow = highlightRow.indexOf(currentRowId) > -1;

                                    //based on login details
                                    const username = row.cells[0].value;
                                    const ipAddress = row.cells[1].value;

                                    if (!userLoginTracker[username]) {
                                        userLoginTracker[username] = { firstIp: ipAddress, allIps: [] };
                                    }
                                    const { firstIp } = userLoginTracker[username];
                                    const isHighlighted = title === "loginReport" && ipAddress !== firstIp;
                                    // const isHighlighted = ipAddress !== firstIp;

                                    if (!userLoginTracker[username].allIps.includes(ipAddress)) {
                                        userLoginTracker[username].allIps.push(ipAddress);
                                    }

                                    //based on forced upload declaration
                                    const forceDec = row.original?.force_dec;
                                    const isRed = title === "vehicle" && forceDec === 1;

                                    return (
                                        <tr
                                            {...row.getRowProps()}
                                            style={{
                                                color: isHighlighted || isRed ? "red" : "",
                                            }}
                                    
                                            className="report-cell-tr"
                                        >
                                            {
                                                checkbox && <input 
                                                    type="checkbox"
                                                    checked={isChecked(row.cells)}
                                                    onChange={(e) => {
                                                        handleCheckboxChange(row.cells);
                                                        
                                                    }}
                                                />
                                            }
                                            {
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                {title === "FreightBillRegister" ? (
                                                    <input
                                                        type="checkbox"
                                                        checked={selectedRows.bill_id.includes(row.cells[0].value) ? true : false}
                                                        onChange={() => handleRowCheckBox(row.cells[0].value, row.cells[12].value)}
                                                    />
                                                ) : (
                                                    <div></div>
                                                )}
                                                {canView && (
                                                        <button style={{ width: '50px', marginLeft: '10px' }} onClick={() => handleViewClick(row.cells)}>
                                                        Open
                                                        </button>
                                                    )}
                                                </div>
                                                }
                                            {/* {
                                                title=="FreightBillRegister"?<input
                                                type="checkbox"
                                            
                                                checked={selectedRows.bill_id.includes(row.cells[0].value)?true:false}
                                                onChange={() => handleRowCheckBox(row.cells[0].value,row.cells[12].value)}
                                                />:<div></div>
                                            }
                                            {
                                                canView && <button style={{width:'10px'}} onClick={() => handleViewClick(row.cells)}>
                                                    Open
                                                </button>
                                            } */}
                                            {/* <button onClick={()=> {
                                                    window.open(
                                                        URL = "/bilty",
                                                    )
                                                }
                                            }  >
                                                Ok  
                                            </button>
                                            <button>
                                            <Link 
                                                to="/bilty" 
                                                target="_blank"
                                            > Link !! </Link>
                                            </button> */}
                                            {row.cells.map((cell) => {
                                                // console.log(row, row.cells);
                                                return (
                                                    <td
                                                        onDoubleClick={() => tooglehighlightRow(currentRowId)}
                                                        {...cell.getCellProps()}
                                                        style={{
                                                            textDecoration: highlightCurrentRow ? "underline" : "",
                                                            whiteSpace: "nowrap",
                                                            textAlign: "center",
                                                            maxWidth: "0px",
                                                            overflow: "hidden",
                                                            textTransform: "uppercase",
                                                            // minWidth:"50px",
                                                            // width:"50px"
                                                        
                                                        }}>{cell.render("Cell")}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    );
                                })
                            })()}
                            <tr>
                                {loading ? (
                                    // Use our custom loading state to show a loading indicator
                                    <td colSpan="10000">Loading...</td>
                                ) : (
                                    <td colSpan="10000">
                                        Showing {page.length} of ~{controlledPageCount * pageSize}{" "}
                                        results
                                    </td>
                                )}
                            </tr>
                        </tbody>
                    </table>
                    {/* 
            Pagination can be built however you'd like. 
            This is just a very basic UI implementation:
        */}
                    <div className="pagination">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            {"<<"}
                        </button>{" "}
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            {"<"}
                        </button>{" "}
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            {">"}
                        </button>{" "}
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            {">>"}
                        </button>{" "}
                        <span>
                            Page{" "}
                            <strong>
                                {pageIndex + 1} of {pageOptions.length}
                            </strong>{" "}
                        </span>
                        <span>
                            | Go to page:{" "}
                            <input
                                type="number"
                                defaultValue={pageIndex + 1}
                                onChange={(e) => {
                                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                                    gotoPage(page);
                                }}
                                style={{ width: "100px" }}
                            />
                        </span>{" "}
                        <select
                            value={pageSize}
                            onChange={(e) => {
                                setPageSize(Number(e.target.value));
                            }}
                        >
                            {[10, 20, 30, 40, 50, 100].map((pageSize) => (
                                <option key={pageSize} value={pageSize}>
                                    Show {pageSize}
                                </option>
                            ))}
                        </select>
                        <button onClick={handlePrint}>Print </button>
                    </div>
                </div>
            </div>
        </>
    );
})




export default ReportTable;
