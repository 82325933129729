import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import './DashboardChart.css'; 
import { SERVER_URL } from '../config/config';
import Loader from './Loader';

const DashboardChart = ({ sessionObject }) => {
  const [bookingData, setBookingData] = useState([]); 
  const [stationData, setStationData] = useState([]); 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);  

  
  useEffect(() => {
    const fYear = sessionObject.sessionVariables.financial_year;
  
    const fetchTopData = async () => {
      try {
        const [partiesResponse, stationsResponse] = await Promise.all([
          fetch(`${SERVER_URL}/bilty/top_5_parties?fyear=${fYear}`, { method: 'POST', headers: { 'Content-Type': 'application/json' } }),
          fetch(`${SERVER_URL}/bilty/top_booking_station?fyear=${fYear}`, { method: 'POST', headers: { 'Content-Type': 'application/json' } })
        ]);
  
        if (!partiesResponse.ok || !stationsResponse.ok) {
          throw new Error('Network response was not ok');
        }
  
        const partiesData = await partiesResponse.json();
        const stationsData = await stationsResponse.json();
  
        setBookingData(partiesData.data);
        setStationData(stationsData.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
  
    fetchTopData();
  }, []);
  
  
  if (loading) {
    return <Loader />
  }

  if (error) {
    return <p>Error loading chart data: {error}</p>;  
  }

  // Donught Chart

  const totalAmountForTopBookings = bookingData.reduce((total, party) => total + party.amount, 0);

  const topParties = bookingData
    .sort((a, b) => b.amount - a.amount) 
    .slice(0, 5); 

  const othersAmount = bookingData
    .slice(5) 
    .reduce((total, party) => total + party.amount, 0);

  const formattedTopPartytData = {
    labels: [...topParties.map(party => party.name), 'Others'], 
    datasets: [
      {
        label: 'Amount (₹)',
        data: [...topParties.map(party => party.amount), othersAmount],
        backgroundColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 99, 71, 0.8)'
        ],
        borderColor: [
          'rgba(75, 192, 192, 1)',
          'rgba(255, 99, 132, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 99, 71, 0.8)'
        ],
        borderWidth: 1,
      },
    ],
  };

  const topBookingPartOptions = {
    responsive: true,
    legend: {
      position: 'bottom',
    },
    title: {
      display: true,
      text: 'Top Parties with Most Bookings',
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          const dataset = data.datasets[tooltipItem.datasetIndex];
          const label = data.labels[tooltipItem.index];
          const value = dataset.data[tooltipItem.index];
          const percentage = ((value / totalAmountForTopBookings) * 100).toFixed(2);
          return `${label}: ₹${value.toLocaleString()} (${percentage}%)`
        },
      },
    },
    cutoutPercentage: 70,
  };

  // Bar Chart
  
  const topStations = stationData.sort((a, b) => b.bookings - a.bookings).slice(0, 5);
  const totalAmountForTopStations = topStations.reduce((total, station) => total + station.amount, 0);

  const formattedTopStationData = {
    labels: topStations.map((station) => station.name),
    datasets: [
      {
        label: '',
        data: topStations.map((station) => station.bookings),
        backgroundColor: [
          'rgba(54, 162, 235, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(255, 206, 86, 0.8)',
        ],
        barPercentage: 1.5,
        categoryPercentage: 0.7,
      },
      {
        label: '',
        data: topStations.map((station) => station.amount),
        backgroundColor: [
          'rgba(255, 99, 132, 0.8)',
          'rgba(255, 159, 64, 0.8)',
          'rgba(75, 192, 192, 0.8)',
          'rgba(153, 102, 255, 0.8)',
          'rgba(54, 162, 235, 0.8)',
        ],
        barPercentage: 1.5,
        categoryPercentage: 0.7,
      },
    ],
  };
  

  const topStationOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
      title: {
        display: true,
        text: 'Top 5 Booking Stations with Amount',
      },
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const label = tooltipItem.label;
            const value = tooltipItem.raw;
            const percentage = ((value / totalAmountForTopStations) * 100).toFixed(2);
            return `${label}: ₹${value.toLocaleString()} (${percentage}%)`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          autoSkip: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: true,
        },
      },
    },
  };
  

  return (
    <div className="charts-container"> 
      <div className="chart-card">
        <Doughnut data={formattedTopPartytData} options={topBookingPartOptions} height={400} width={400} /> 
      </div>
      <div className="chart-card">
        <Bar data={formattedTopStationData} options={ topStationOptions } height={400} width={400} /> 
      </div>
      {/* <div className="chart-card">
        <Bar data={formattedChartData} options={ options } height={400} width={400} /> 
      </div> */}
    </div>
  );
};
export default DashboardChart;
