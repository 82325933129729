import React from 'react';
import '../../App.css';
import DashboardChart from '../DashboardChart';
function AnalysisChartPage({ sessionObject }) {
  if(!sessionObject.sessionVariables["modules"].includes("analysis-chart")){
    return;}
    return (
      <div className="page-bilty">
        <DashboardChart sessionObject={sessionObject} />
      </div>
    );
}
export default AnalysisChartPage;