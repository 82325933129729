// import React, { useEffect, useState, useRef } from "react";
// import { SERVER_URL } from "../config/config";
// import { useHistory } from 'react-router-dom';

// const AddPhoto = () => {
//   const history = useHistory();
//   const videoRef = useRef(null);
//   const [photo, setPhoto] = useState(null);
//   const [cameraActive, setCameraActive] = useState(true);
//   const [captureDisabled, setCaptureDisabled] = useState(true);

//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: true,
//       });
//       videoRef.current.srcObject = stream;
//       setCaptureDisabled(false);
//     } catch (error) {
//       console.error("Error accessing camera:", error);
//     }
//   };

//   const stopCamera = async () => {
//     if(videoRef.current){
//       const tracks = videoRef.current.srcObject.getTracks();
//       tracks.forEach((track) => track.stop());
//     }
//     setCaptureDisabled(true);
//   };

//   useEffect(() => {
//     if (cameraActive) {
//       startCamera();
//     } else {
//       stopCamera();
//     }
//   }, [cameraActive]);

//   useEffect(() => {
//     return () => {
//       if (videoRef.current) {
//         stopCamera();
//       }
//     };
//   }, []);

//   const handleImageUpload = async () => {
//     stopCamera();
//     const file = await new Promise((resolve) => {
//       const input = document.createElement("input");
//       input.type = "file";
//       input.accept = "image/*";
//       input.onchange = (event) => resolve(event.target.files[0]);
//       input.click();
//     });
//     if(biltyIdFromUrl){
//       const apiUrl = `${SERVER_URL}/pod_image/check-qr?image_id=${biltyIdFromUrl}&type=${"bilty_pod"}&client_name=${"rcc"}`;
//       const formData = new FormData();
//       formData.append("file", file);

//       try {
//         const response = await fetch(apiUrl, {
//           method: "POST",
//           body: formData,
//         });
  
//         const data = await response.json();
  
//         if (!response.ok || data.status_code == 406) {
//           alert(`Error: ${data.detail}`);
//         } else {
//           alert("Image uploaded successfully");
//           history.push('/');
//         }
//       } catch (error) {
//         alert("An unexpected error occurred. Please try again.");
//         console.error("Error uploading image:", error);
//       }
      
//       // const response = await fetch(apiUrl, { method: "POST", body: formData });
//       // alert("Image uploaded successfully")
//       // history.push('/');
//     };   
//     setCaptureDisabled(false);
//   };    

//   const capturePhoto = () => {
//     const canvas = document.createElement("canvas");
//     canvas.width = videoRef.current.videoWidth;
//     canvas.height = videoRef.current.videoHeight;
//     const ctx = canvas.getContext("2d");
//     ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
//     const capturedPhoto = canvas.toDataURL("image/png");
//     setPhoto(capturedPhoto);
//     stopCamera();
//   };

//   const regeneratePhoto = () => {
//     setPhoto(null);
//     setCameraActive(true);
//     startCamera();
//   };

//   const urlParams = new URLSearchParams(window.location.search);
//   const biltyIdFromUrl = urlParams.get("bilty_id");

//   const sendPhotoToAPI = async () => {
//     if(biltyIdFromUrl){
//       const apiUrl = `${SERVER_URL}/pod_image/check-qr?image_id=${biltyIdFromUrl}&type=${"bilty_pod"}&client_name=${"rcc"}`;
//       const formData = new FormData();
//       formData.append("file", new File([new Blob([photo])], "yash.jpeg"));

//       try {
//         const response = await fetch(apiUrl, {
//           method: "POST",
//           body: formData,
//         });
  
//         const data = await response.json();
  
//         if (!response.ok || data.status_code == 406) {
//           alert(`Error: ${data.detail}`);
//         } else {
//           alert("Image uploaded successfully");
//           history.push('/');
//         }
//       } catch (error) {
//         alert("An unexpected error occurred. Please try again.");
//         console.error("Error uploading image:", error);
//       }
      
//       // const response = await fetch(apiUrl, { method: "POST", body: formData });
//       // alert("Image uploaded successfully")
//       // history.push('/');
//     }
//   };



//   return (
//     <div className="page-addPhoto">
//       <span>Add photo of POD</span>
//       <div className="capture-area">
//         <div className="capture-btn">
//           {!photo && <button onClick={handleImageUpload}>Choose File</button>}
//           {!photo && <button onClick={capturePhoto} disabled={captureDisabled}>Capture</button>}
//           {photo && <button onClick={regeneratePhoto}>Regenrate</button>}
//           {photo && <button disabled={!biltyIdFromUrl} onClick={sendPhotoToAPI}>Submit</button>}
//         </div>
//         {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
//         {photo && <img src={photo} alt="Captured" className="captured-size" />}
//       </div>
//     </div>
//   );
// };

// export default AddPhoto;



import React, { useEffect, useState, useRef } from "react";
import { SERVER_URL } from "../config/config";
import { useHistory } from "react-router-dom";

const AddPhoto = () => {
  const history = useHistory();
  const videoRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [cameraActive, setCameraActive] = useState(true);
  const [captureDisabled, setCaptureDisabled] = useState(true);
  const [facingMode, setFacingMode] = useState("environment"); // Default to back camera

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: { facingMode },
      });
      videoRef.current.srcObject = stream;
      setCaptureDisabled(false);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = async () => {
    if (videoRef.current) {
      const tracks = videoRef.current.srcObject?.getTracks();
      tracks?.forEach((track) => track.stop());
    }
    setCaptureDisabled(true);
  };

  const toggleCamera = () => {
    setFacingMode((prevMode) => (prevMode === "environment" ? "user" : "environment"));
  };

  useEffect(() => {
    if (cameraActive) {
      startCamera();
    } else {
      stopCamera();
    }
  }, [cameraActive, facingMode]); // Restart camera when facingMode changes

  useEffect(() => {
    return () => {
      if (videoRef.current) {
        stopCamera();
      }
    };
  }, []);

  const handleImageUpload = async () => {
    stopCamera();
    const file = await new Promise((resolve) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "image/*";
      input.onchange = (event) => resolve(event.target.files[0]);
      input.click();
    });

    if (biltyIdFromUrl) {
      const apiUrl = `${SERVER_URL}/pod_image/check-qr?image_id=${biltyIdFromUrl}&type=bilty_pod&client_name=rcc`;
      const formData = new FormData();
      formData.append("file", file);

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (!response.ok || data.status_code === 406) {
          alert(`Error: ${data.detail}`);
        } else {
          alert("Image uploaded successfully");
          history.push("/");
        }
      } catch (error) {
        alert("An unexpected error occurred. Please try again.");
        console.error("Error uploading image:", error);
      }
    }
    setCaptureDisabled(false);
  };

  const capturePhoto = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const capturedPhoto = canvas.toDataURL("image/png");
    setPhoto(capturedPhoto);
    stopCamera();
  };

  const regeneratePhoto = () => {
    setPhoto(null);
    setCameraActive(true);
    startCamera();
  };

  const urlParams = new URLSearchParams(window.location.search);
  const biltyIdFromUrl = urlParams.get("bilty_id");

  const sendPhotoToAPI = async () => {
    if (biltyIdFromUrl) {
      const apiUrl = `${SERVER_URL}/pod_image/check-qr?image_id=${biltyIdFromUrl}&type=bilty_pod&client_name=rcc`;
      const formData = new FormData();
      formData.append("file", new File([new Blob([photo])], "captured_image.png"));

      try {
        const response = await fetch(apiUrl, {
          method: "POST",
          body: formData,
        });

        const data = await response.json();

        if (!response.ok || data.status_code === 406) {
          alert(`Error: ${data.detail}`);
        } else {
          alert("Image uploaded successfully");
          history.push("/");
        }
      } catch (error) {
        alert("An unexpected error occurred. Please try again.");
        console.error("Error uploading image:", error);
      }
    }
  };

  return (
    <div className="page-addPhoto">
      <span>Add photo of POD</span>
      <div className="capture-area">
        <div className="capture-btn">
          {!photo && <button onClick={handleImageUpload}>Choose File</button>}
          {!photo && <button onClick={capturePhoto} disabled={captureDisabled}>Capture</button>}
          {!photo && (
            <button onClick={toggleCamera} disabled={captureDisabled}>
              Back Camera
            </button>
          )}
          {photo && <button onClick={regeneratePhoto}>Regenerate</button>}
          {photo && <button disabled={!biltyIdFromUrl} onClick={sendPhotoToAPI}>Submit</button>}
        </div>
        {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
        {photo && <img src={photo} alt="Captured" className="captured-size" />}
      </div>
    </div>
  );
};

export default AddPhoto;
