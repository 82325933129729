import React from "react";
import DynamicViewTable from "./DynamicViewTable";
import Popup from "reactjs-popup";
import { vehicleApiConfig } from "../config/apiConfig.js";
import { groupInfo, dataObject, validate } from "../config/vehicleForm.js";
import FormColumn from "./FormColumn.js";
import { Prompt } from "react-router-dom";
import Autosuggest from "react-autosuggest";
import "./Form.css";
import "./ChallanForm.css";
import "./AutoSuggest.css";
import useForm from "./useForm";
import "./popup.css";
import { SERVER_URL, USE_OVERLAY } from "../config/config";
import LoadingOverlay from "react-loading-overlay";
import redCloseIcon from '../assets/images/redCloseIcon.jpeg';
import greenTickIcon from '../assets/images/greenTickIcon.png'
import { useState, useEffect, useRef } from "react";
import { imageShower } from "./apis.js";
import { ownerNameMatched,handleSave} from "../config/vehicleForm.js";





const VehicleForm = ({ sessionObject }) => {
  const [data, setData] = useState(false)
  const buttonRef = useRef(null)
  const [jt, setJt] = useState(true)
  const [handleOtp, setHandleOtp] = useState(true)
  const [handleTransfer, setHandleTransfer] = useState(false)
  const [saveDisable, setSaveDisable] = useState(false)
  const [selectedRCImage, setSelectedRCImage] = useState(null)
  const [selectedPANImage, setSelectedPANImage] = useState(null)
  const [selectedDeclareImage, setSelectedDeclareImage] = useState(null)
  const [selectedTransferImage, setSelectedTransferImage] = useState(null)
  const [selectTemp, setSelectTemp] = useState(false)
  const [showRCDownload, setShowRCDownload] = useState(false)
  const [showPANDownload, setShowPANDownload] = useState(false)
  const [showDECLAREDownload, setShowDECLAREDownload] = useState(false)
  const [showTransferDownload, setShowTransferDownload] = useState(false)
  const [downloadType, setDownloadType] = useState(null)
  const [secondOwnerName, setSecondOwnerName] = useState(false)
  const [uploadRcAgain, setUploadRcAgain] = useState(false)
  const [uploadPanAgain, setUploadPanAgain] = useState(false)
  const [rcAgain, setRcAgain] = useState(null)
  const [panAgain, setPanAgain] = useState(null)
  const [vehicleInfo, setVehicleInfo] = useState(null)
  const [showVehiclePopup, setShowVehiclePopup] = useState(true);
  const [panNo,setPanNo] = useState('')
  const [showDeclarationPopup, setShowDeclarationPopup] = useState(false);
  const [declarationErrorMessage, setDeclarationErrorMessage] = useState("");
  const [checkDate, setCheckDate] = useState(true);
  const [storedDeclarationImage, setStoredDeclarationImage] = useState(null);
  const [forceDec, setForceDec] = useState(0);


  useEffect(() => {
    if (imageShower !== undefined) {
      if (imageShower?.is_rc_image === 1) {
        setSelectedRCImage(`${SERVER_URL}/vehicle/vehicle/download_rc?vehicle_no=${imageShower.vehicle_no}`)
      }
      if (imageShower?.is_pan_image === 1) {
        setSelectedPANImage(`${SERVER_URL}/vehicle/vehicle/download_pan?pan_no=${imageShower.pan_no}`)
      }
      if (imageShower?.is_declaration_image === 1) {
        setSelectedDeclareImage(`${SERVER_URL}/vehicle/vehicle/download_declaration?vehicle_no=${imageShower.vehicle_no}`)
      }
      if (imageShower?.is_transfer_paper_image === 1) {
        setSelectedTransferImage(`${SERVER_URL}/vehicle/vehicle/download_transfer_paper?vehicle_no=${imageShower.vehicle_no}`)
      }
    }
  }, [imageShower]);

  const [ownerNameMismatch, setOwnerNameMismatch] = useState(false);
  let disableSaveElement;
 
  // In this useEffect , I'm setting the disable for Save button on condition of Transfer Paper is uploaded or not ,in case of owner name mismatch in pan and RC
  useEffect(() => {
    if (!ownerNameMismatch) {
      if (selectedTransferImage) {
        setSaveDisable(false)
        disableSaveElement = false
      }
    }
    if (selectedPANImage) {
      if (ownerNameMatched && sessionObject.sessionVariables.role_id == "1") {
        // //// debugger
        setSaveDisable(false);
        disableSaveElement = false;
      }
      if (!ownerNameMatched) {
        setSaveDisable(true);
        disableSaveElement = true;
      }
    }
    if(handleSave && sessionObject.sessionVariables.role_id == "1"){
      //// debugger
      setSaveDisable(false)
    }
  }, [ownerNameMismatch, ownerNameMatched,handleSave])

  useEffect(()=>{
    setSelectedPANImage(null)
    setSelectedRCImage(null)
    setSelectedDeclareImage(null)
    setSelectedTransferImage(null)
  },[])

  const contentStyle = {
    maxWidth: "600px",
    width: "90%",
  };

  let variablesFromSession = {
    station_from: String(sessionObject.sessionVariables.branch_id),
    station_from_name: sessionObject.sessionVariables.branch_name,
    created_from: String(sessionObject.sessionVariables.branch_id),
    created_by: String(sessionObject.sessionVariables.user_id),
    roleId: String(sessionObject.sessionVariables.role_id),
  };

  const myForm = useForm(
    "Vehicle",
    validate,
    { ...dataObject, ...variablesFromSession },
    vehicleApiConfig
  );

  const videoRef = useRef(null);
  const [photo, setPhoto] = useState(null);
  const [cameraActive, setCameraActive] = useState(false);
  const [captureDisabled, setCaptureDisabled] = useState(true);

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      videoRef.current.srcObject = stream;
      setCaptureDisabled(false);
    } catch (error) {
      console.error("Error accessing camera:", error);
    }
  };

  const stopCamera = async () => {
    if (videoRef.current) {
      const tracks = videoRef.current.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
    }
    setCaptureDisabled(true);
  };

  useEffect(() => {
    if (cameraActive) {
      startCamera();
    } else {
      if (videoRef.current) {
        stopCamera();
      }
    }
  }, [cameraActive]);

  const capturePhoto = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const capturedPhoto = canvas.toDataURL("image/png");
    setPhoto(capturedPhoto);
    stopCamera();
  };

  const regeneratePhoto = async () => {
    setPhoto(null);
    setCameraActive(true);
    await startCamera();
  };

  const sendPhotoToAPI = async (key) => {
    let uploadingImage = photo;
    uploadingImage = new File([
      new Blob([uploadingImage])
    ], "yash.jpeg");
    setCameraActive(false);
    setPhoto(null);
    handleImageUpload(key, uploadingImage)
  }
  const [isOpenRc, setIsOpenRc] = useState(false);
  const [isOpenPan, setIsOpenPan] = useState(false);
  const [isOpenDeclaration, setIsOpenDeclaration] = useState(false);
  const [isOpenTransfer, setIsOpenTransfer] = useState(false);
  const [toggleMode, setToggleMode] = useState();

  const showFlagRc = () => {
    setIsOpenRc(!isOpenRc);
    setToggleMode("rc");
  };
  const showFlagPan = () => {
    setIsOpenPan(!isOpenPan);
    setToggleMode("pan");
  };
  const showFlagDeclaration = () => {
    setIsOpenDeclaration(!isOpenDeclaration);
    setToggleMode("declaration");
  };
  const showFlagTransfer = () => {
    setIsOpenTransfer(!isOpenTransfer);
    setToggleMode("transferpaper");
  };


  // useEffect(() => {
  //   if (myForm.pageState && myForm.pageState.pan_link_status === "1") {
  //     setShowPopup(true);
  //   } else {
  //     setShowPopup(false);
  //   }
  // }, [myForm.pageState,showPopup]);


  // const handleButtonClick = () => {
  //   // setData(true);
  //   let newState = {
  //     pan_link_status:"0",
  //   };
  //   myForm.setPageState({
  //     ...myForm.pageState,
  //     ...newState,
  //   });

  // };

  // const handleClose = () => {
  //   setShowPopup(false);
  // };


  const handleImagePopUp = (type) => {
    setDownloadType(type)
    setShowRCDownload(true)
    setShowPANDownload(true)
    setShowDECLAREDownload(true)
    setShowTransferDownload(true)
  }


  const getAdditionalInfoForSuggestionFetch = (fieldInfoObject) => {
    // if (fieldInfoObject.name == "vehicle_no"){
    //   return {"limit": "10"};
    // }
    return null;
  };

  const checkDisabledCondition = (fieldInfo) => {
    return ""
  };

  const checkVisibilityCondition = (fieldInfo) => {
    if (
      fieldInfo.name == "entry_date"
    ) {
      return false;
    }
    return true
  };

  const handleInvalidOtpCall = async () => {
    const url = SERVER_URL + "/token_verification/expire?"
      + `token=${myForm.pageState.vehicle_no}`;

    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  const handleSendOTP = async () => {
    const currOtp = parseInt(myForm.pageState.otp);

    const url = SERVER_URL + "/token_verification/verify?"
      + `token=${myForm.pageState.vehicle_no}`
      + `&otp=${currOtp}`;
    let resp = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });

    if (resp.ok) {
      const respData = await resp.json();

      if (respData.flag == 1) {
        // save
        myForm.setPageState((oldState) => ({
          ...oldState,
          "otp_success": true,
        }))

        document.getElementById("send_button").click();
      }
      else {
        // if attempts Remaining == -1 and this resp was also negative
        if (myForm.pageState.otp_attempts == 1) {
          await handleInvalidOtpCall();
          window.location.reload();
        }
        else {
          myForm.setPageState((oldState) => ({
            ...oldState,
            "otp": "",
            "otp_attempts": parseInt(myForm.pageState.otp_attempts) - 1,
          }))
          document.getElementById("otp").focus();
        }
      }
    }
  }

  const handleDownloadImage = async (downloadType) => {
    try {
      let apiUrl;

      switch (downloadType) {
        case 'rc':
          apiUrl = `${SERVER_URL}/vehicle/vehicle/download_rc?vehicle_no=${myForm.pageState.vehicle_no.toLowerCase()}`;
          break;
        case 'pan':
          apiUrl = `${SERVER_URL}/vehicle/vehicle/download_pan?pan_no=${myForm.pageState.pan_no.toLowerCase()}`;
          break;
        case 'declaration':
          apiUrl = `${SERVER_URL}/vehicle/vehicle/download_declaration?vehicle_no=${myForm.pageState.vehicle_no.toLowerCase()}`;
          break;
        case 'transferpaper':
          apiUrl = `${SERVER_URL}/vehicle/vehicle/download_transfer_paper?vehicle_no=${myForm.pageState.vehicle_no.toLowerCase()}`;
          break;
        default:
          throw new Error('Invalid download type');
      }

      const response = await fetch(apiUrl,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const downloadLink = document.createElement('a');
      const blobUrl = URL.createObjectURL(blob);
      downloadLink.href = blobUrl;

      downloadLink.download = `${downloadType}.jpg`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  const handleImageRemove = () => {
    setSelectedDeclareImage(null); setShowRCDownload(false);
    myForm.setPageState(oldState => ({
      ...oldState,
      is_declaration_image: "0",
    })); 
  }

  const handleImageUpload = async (type, photo) => {
    try {
      // setSelectedImage(photo)
      setIsOpenRc(false);
      setIsOpenPan(false);
      setIsOpenDeclaration(false);
      setIsOpenTransfer(false);
      setShowPANDownload(false)

      let file;
      if (photo) {
        file = photo;
      } else {
        file = await new Promise((resolve) => {
          const input = document.createElement("input");
          input.type = "file";
          input.accept = "image/*";
          input.onchange = (event) => resolve(event.target.files[0]);
          input.click();
        });
      }

      if (type === "declaration") {
        setStoredDeclarationImage(file);
      }

      if(type == "rc") {
        setRcAgain(file)
      }

      if(type == "pan") {
        setPanAgain(file)
      }

      myForm.setOverlay(true);

      const imagePreviewRC = document.getElementById("imagePreviewRC");

      // if (file) {
      //   const reader = new FileReader();
      //   reader.onload = (event) => {
      //     imagePreview.src = event.target.result;
      //   };

      //   reader.readAsDataURL(file);
      const reader = new FileReader();
      if (file) {
        let api, key, updateValueKey;
        if (type === "rc") {
          api = `${SERVER_URL}/vehicle/upload_rc?type=${"rc_book"}&client_name=${"rcc"}`;
          key = "vehicle_no_image";
          updateValueKey = "vehicle_no";

          reader.onload = (event) => {
            // imagePreviewRC.src = event.target.result;
            setSelectedRCImage(event.target.result)

          };

        } else if (type === "pan") {
          api = `${SERVER_URL}/vehicle/upload_pan?type=${"pan"}&client_name=${"rcc"}`;
          key = "pan_no_image";
          updateValueKey = "pan_no";
          reader.onload = (event) => {
            // imagePreviewRC.src = event.target.result;
            setSelectedPANImage(event.target.result)
            setSecondOwnerName(true)

          };
        } else if (type === "declaration") {
          api = `${SERVER_URL}/vehicle/upload_declaration?image_id=${myForm.pageState.vehicle_no}&type=${"declaration"}&client_name=${"rcc"}&check_date=${checkDate}`;
          key = "policy_no_image";
          updateValueKey = "policy_no";
          reader.onload = (event) => {
            // imagePreviewRC.src = event.target.result;
            setSelectedDeclareImage(event.target.result)
          };

        } else if (type === "transferpaper") {
          api = `${SERVER_URL}/vehicle/upload_transfer_paper?image_id=${myForm.pageState.vehicle_no}&type=${"transfer_paper"}&client_name=${"rcc"}`;
          key = "policy_no_image";
          // updateValueKey = "policy_no";
        }

        const formData = new FormData();
        formData.append("file", file);

        const response = await fetch(api, {
          method: "POST",
          body: formData,
        });


        if (response.ok) {
          const responseData = await response.json();
          if(type == "rc") {
            if(responseData.msg == "Vehicle numer not visible or invalid") {
              alert("Vehicle numer not visible or invalid.");
              setUploadRcAgain(true)
              responseData.vehicle_no = "";
            }
          }
          if(type == "pan") {
            if(responseData.msg == "Pan numer not visible or invalid") {
              setUploadPanAgain(true)
              responseData.pan_no = "";
            }
          }
        
          if (type === "declaration") {
            if (responseData && responseData.error) {
              let errorMessage = "";
              if (responseData.error === "Date is outside the current financial year.") {
                errorMessage = "Declaration year mismatched";
              } else if (responseData.error === "No valid date mentioned in the image.") {
                errorMessage = "No valid date found in the image.";
              } else if (responseData.error === "No valid date or financial year mentioned in the image.") {
                errorMessage = "No valid date or financial year mentioned in the image.";
              } else {
                errorMessage = "Something went wrong."
              }
              
              setDeclarationErrorMessage(errorMessage); 
              setCheckDate(false); 
              setShowDeclarationPopup(true);
              myForm.setOverlay(false);
              // return;
            }
          }

          if(responseData?.pan_no == panNo || panNo == "") {
            setShowVehiclePopup(false)
          } else {
            setShowVehiclePopup(true)
          }
          // setSelectedImage(file.name)
          // if(responseData==null){
          //   setHandleTransfer(true)
          // }
          if (type == "transferpaper") {
            reader.onload = (event) => {
              // imagePreviewRC.src = event.target.result;
              setSelectedTransferImage(event.target.result)
            };
            setOwnerNameMismatch(false);
          }
          setHandleOtp(false)
          let newObject = {
            [key]: responseData,
          };


          if (updateValueKey !== undefined) {
            if (updateValueKey === "policy_no") {
              newObject[updateValueKey] = "Yes";
            } else if (updateValueKey === "vehicle_no") {
              fetchVehicleDetails(responseData, myForm, key);
            }
            else if (updateValueKey === "pan_no") {
              let ownerName = "";
              if (
                myForm.pageState.vehicle_no_image &&
                myForm.pageState.vehicle_no_image.name
              ) {
                let rc_name = myForm.pageState.vehicle_no_image.name.split(" ");
                // remove the texts like date of , son , '/' , name , etc from the rc_name array
                rc_name = rc_name.filter((word) => {
                  return word.toLowerCase() !== "of" && word.toLowerCase() !== "date" && word.toLowerCase() !== "son" && word.toLowerCase() !== "name" && word.toLowerCase() !== "no" && word.toLowerCase() !== "no." && word.toLowerCase() !== "/"
                });
                let temp_name = rc_name;

                let pan_name = responseData.name;

                // get the index of the first element of rc_name in pan_name array
                let index = pan_name.indexOf(rc_name[0]);
                while (rc_name.length > 0 && index == -1) {
                  // if not found, remove the first element of rc_name and get the index of the first element of rc_name in pan_name array
                  rc_name.shift();
                  index = pan_name.indexOf(rc_name[0]);
                }
                if (index) {
                  // if found, get the substring of pan_name from index-2 to index+3
                  ownerName = pan_name[index];
                  let pan_name_to_be_searched = pan_name.slice(index - 2, index + 3);
                  for (let i = 1; i < rc_name.length; i++) {
                    for (let j = 0; j < pan_name_to_be_searched.length; j++) {
                      if (rc_name[i] === pan_name_to_be_searched[j]) {
                        ownerName = ownerName + " " + pan_name_to_be_searched[j];
                      }
                    }
                  }
                  if (ownerName == undefined) {
                    ownerName = ""
                  }
                  if (ownerName.split(' ').length < 2) {
                    ownerName = temp_name.join(' ');
                  }
                  console.log(ownerName)
                }
              }
              pan_status(myForm, responseData, ownerName, key);
            }
          }

          myForm.setPageState({
            ...myForm.pageState,
            ...newObject,
          });

          myForm.setOverlay(false);
        } else if (!response.ok) {
          const responseData = await response.json();
          myForm.setOverlay(false);
          alert(responseData.detail);
        } else {
          console.error(`Error uploading ${key}`);
          myForm.setOverlay(false);
          stopCamera();
          setCameraActive(false);
        }
      }
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("Error handling image upload:", error);
      myForm.setOverlay(false);
    }

  };


  const fetchVehicleDetails = async (responseData, myForm, keydata) => {
    try {
      const apiUrl = `${SERVER_URL}/vehicle/${responseData.vehicle_no}`;
      const response = await fetch(apiUrl);
      if (response.ok) {
        const vehicleData = await response.json();
        if(vehicleData?.pan_no){
          setPanNo(vehicleData.pan_no)
        }
        if(vehicleData.detail == "Vehicle not found"){
          setShowVehiclePopup(false)
        }
        const newObject = {};
        const suggestionSchema = {
          vehicle_no: "vehicle_no",
          vehicle_id: "vehicle_id",
          pan_no: "pan_no",
          owner_name: "owner_name",
          owner_address: "owner_address",
          body_type: "body_type",
          engine_no: "engine_no",
          chasis_no: "chasis_no",
          permit_no: "permit_no",
          permit_validity: "permit_validity",
          insurance_company: "insurance_company",
          policy_validity: "polciy_validity",
          policy_no: "policy_no",
          drivers_name: "drivers_name",
          driver_address: "driver_address",
          license_no: "license_no",
          license_validity: "license_validity",
          vehicle_ownership: "vehicle_ownership",
          second_owner_name: "second_owner_name",
          second_owner_address: "second_owner_address",
          second_owner_pan_no: "second_owner_pan_no",
          third_owner_name: "third_owner_name",
          third_owner_address: "third_owner_address",
          third_owner_pan_no: "third_owner_pan_no",
          second_owner_entry_date: "second_owner_entry_date",
          third_owner_entry_date: "third_owner_entry_date",
          modified_date: "entry_date",
          verified_by: "verified_by",
          tds_rate: "tds_rate",
          is_active: "is_active",
          effective_date: "effective_date",
          pan_adhar_link_status: "pan_adhar_link_status",
          is_rc_image: "is_rc_image",
          is_pan_image: "is_pan_image",
          is_declaration_image: "is_declaration_image",
          is_transfer_paper_image: "is_transfer_paper_image"
        }

        Object.keys(suggestionSchema).forEach((key) => {
          if (vehicleData[suggestionSchema[key]] !== null && vehicleData[suggestionSchema[key]] !== undefined) {
            newObject[key] = vehicleData[suggestionSchema[key]];
            newObject[keydata] = responseData;
          }
          else if (responseData) {
            newObject["vehicle_no"] = responseData.vehicle_no;
            newObject[keydata] = responseData;
          }
        });

        setVehicleInfo(vehicleData)

        if (newObject?.is_rc_image === 1) {
          setSelectedRCImage(`${SERVER_URL}/vehicle/vehicle/download_rc?vehicle_no=${newObject.vehicle_no}`)
        }
        if (newObject?.is_pan_image === 1) {
          setSelectedPANImage(`${SERVER_URL}/vehicle/vehicle/download_pan?pan_no=${newObject.pan_no}`)
        }
        if (newObject?.is_declaration_image === 1) {
          setSelectedDeclareImage(`${SERVER_URL}/vehicle/vehicle/download_declaration?vehicle_no=${newObject.vehicle_no}`)
        }
        if (newObject?.is_transfer_paper_image === 1) {
          setSelectedTransferImage(`${SERVER_URL}/vehicle/vehicle/download_transfer_paper?vehicle_no=${newObject.vehicle_no}`)
        }

        myForm.setPageState({
          ...myForm.pageState,
          ...newObject,
        });

      } else {
        console.error("Error fetching vehicle details");
      }
    } catch (error) {
      console.error("Error fetching vehicle details:", error);
    }
  };

  const pan_status = async (myForm, responseData, ownerName, keydata) => {
    myForm.setOverlay(true);
  
  if (responseData.pan_no) {
    const checkPanAdharLinkStatusUrl = `${SERVER_URL}/vehicle/check_pan_adhar_link_status?pan_no=${responseData.pan_no}`;

    const checkPanAdharLinkStatusResponse = await fetch(checkPanAdharLinkStatusUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (checkPanAdharLinkStatusResponse.ok) {
      const linkStatusData = await checkPanAdharLinkStatusResponse.json();
      let newObject = {
        [keydata]: responseData,
        owner_name: ownerName,
        pan_no: responseData.pan_no,
        pan_link_status: "1",
        pan_link_status_message: linkStatusData.message,
        pan_link_status_code: linkStatusData.code,
      }
      newObject.save_disabled = false;
      if (ownerName == "") {
        setOwnerNameMismatch(true);
        alert("Mismatch in owner name of RC and PAN. Please contact your Admin.");
        setJt(false);
        if (sessionObject.sessionVariables.role_id != "1") {
          setSaveDisable(true);
        }
        newObject.save_disabled = true;
      }
      if (!newObject.pan_no_image.name.includes(ownerName.split(' ')[0])) {
        newObject.owner_name = "";
      }
      if (parseInt(linkStatusData.code) == 1001) {
        newObject.pan_adhar_link_status = "1";
        setSaveDisable(false);
      } else if (parseInt(linkStatusData.code) == 1002) {
        newObject.pan_adhar_link_status = "0";
        if (sessionObject.sessionVariables.role_id != "1") {
          setSaveDisable(true);
        }
      } else if (parseInt(linkStatusData.code) == 1003) {
        newObject.pan_adhar_link_status = "2";
      } else if (parseInt(linkStatusData.code) == 1000) {
        newObject.pan_adhar_link_status = null;
      } else if (parseInt(linkStatusData.code) == 1004) {
        newObject.pan_adhar_link_status = "3";
        newObject.pan_link_status_message = "Invalid Pan Number";
      }
      myForm.setPageState({
        ...myForm.pageState,
        ...newObject,
      });
    } else {
      console.error("Error checking PAN-Adhar link status");
    }
  } else {
    console.error("PAN number is undefined");
  }

  return;
}

  useEffect(() => {
    if(myForm.pageState.pan_link_status_code == 1001 && myForm.pageState.policy_no == "No") {
      myForm.setPageState({
        ...myForm.pageState,
        tds_rate:1
      });
    }
    if(myForm.pageState.pan_link_status_code == 1001 && myForm.pageState.policy_no == "Yes") {
      myForm.setPageState({
        ...myForm.pageState,
        tds_rate:0
      });
    }
    if(myForm.pageState.pan_link_status_code == 1001 && myForm.pageState.policy_no == "Yes" && myForm.pageState.is_more_than_ten == 1) {
      myForm.setPageState({
        ...myForm.pageState,
        tds_rate:1
      });
    }
    if(myForm.pageState.pan_link_status_code == 1003 && myForm.pageState.policy_no == "No") {
      myForm.setPageState({
        ...myForm.pageState,
        tds_rate:2
      });
    }
    if(myForm.pageState.pan_link_status_code == 1003 && myForm.pageState.policy_no == "Yes") {
      myForm.setPageState({
        ...myForm.pageState,
        tds_rate:0
      });
    }
    if(myForm.pageState.pan_link_status_code == 1003 && myForm.pageState.policy_no == "Yes" && myForm.pageState.is_more_than_ten == "1") {
      myForm.setPageState({
        ...myForm.pageState,
        tds_rate:2
      });
    }
  },[myForm.pageState.policy_no,myForm.pageState.pan_adhar_link_status,myForm.pageState.is_more_than_ten])
  
  const matchOwnerNameWithPan = async () => {
    if (myForm.pageState.pan_no_image.pan_no) {
      if (myForm.pageState.pan_no_image.name.includes(myForm.pageState.owner_name)) {
        console.log("Owner name matched with PAN");
      }
    }
  }

  return (
    <div className="challan-form-container">
      {
        USE_OVERLAY && (
          <LoadingOverlay
            active={myForm.overlay}
            spinner
            text="Loading your content..."
            styles={{
              wrapper: {
                // width: '400px',
                // height: '400px',
                overflow: true ? "hidden" : "scroll",
              },
            }}
          ></LoadingOverlay>
        )
      }
      <div>
        <Popup
          // trigger={<button className="button"> Open Modal </button>}
          open={myForm.pageMode == "submitted" || myForm.pageMode == "error"}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">
                {" "}
                {myForm.pageMode == "submitted" ? (
                  <div>Vehicle Saving Successful </div>
                ) : (
                  <div>Error In Vehicle Module </div>
                )}
                <div>
                  <a className="pop-up-close btn" onClick={close}>
                    &times;
                  </a>
                </div>
              </div>
              {myForm.pageMode == "submitted" ? (
                <div className="pop-up-content">
                  {" "}
                  Vehicle Master Saved Successfully:-
                  <br />
                  <div className="pop-up-fields">
                    <div className="pop-up-field">
                      <div className="pop-up-field-label">Vehicle No</div>
                      <div className="pop-up-field-value">
                        {myForm.pageState.vehicle_no}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="pop-up-content">
                  {" "}
                  Error in Vehicle Master with the following info:-
                  <br />
                  <div className="pop-up-fields">
                    <div className="pop-up-field">
                      <div className="pop-up-field-label">Error:</div>
                      <div className="pop-up-field-value">
                        {myForm.popupError}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  onClick={() => {
                    if (myForm.pageMode == "submitted") {
                      // sessionObject.saveSessionVariableByField("last_bilty_no", {"last_bilty_no":myForm.pageState.bilty_no})
                      myForm.setPageState({
                        ...dataObject,
                        ...variablesFromSession,
                      });
                      myForm.setPageMode("write");
                      window.location.reload();
                      close();
                    } else {
                      myForm.setPageMode("write");
                      close();
                    }
                  }}
                >
                  Okay
                </button>
              </div>
            </div>
          )}
        </Popup>
      </div>
      <div>
        <Popup
          // trigger={<button className="button"> Open Modal </button>}
          open={myForm.pageState.pan_link_status == "1"}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        // onClose={()=>{myForm.pageState.pan_link_status="0"}}
        >
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">Pan Link Status</div>
              <div className="pop-up-content">
                <span className="align-center">
                  {myForm.pageState.pan_link_status_code !== 1001 ? <img src={redCloseIcon} alt="Error" height={20} width={20} /> :
                    <img src={greenTickIcon} alt="Error" height={20} width={20} />}
                  <p style={{ color: myForm.pageState.pan_link_status_code !== 1001 ? "red" : "green" }}>
                    {myForm.pageState.pan_link_status_message}
                    {/* {myForm.pageState.pan_link_status_message=="Company PAN no provided thus no link verification required"?
                    myForm.pageState.tds_rate=2:myForm.pageState.tds_rate=1} */}
                  </p>
                </span>
              </div>
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  onClick={async () => {
                    const url = SERVER_URL + "/vehicle/pan_count/" + myForm.pageState.pan_no;
                    myForm.setOverlay(true);
                    const response = await fetch(url);
                    if (!response.ok) {
                      myForm.setPageStateByField("pan_no", "error");
                      myForm.setOverlay(false);
                      return;
                    }
                    const temp_response = await response.json();

                    // if (temp_response.truck_limit_exceed == 1){
                    //   return;
                    // }
                    myForm.setOverlay(false);
                    // if(myForm.pageState.pan_adhar_link_status == 0){
                    //     myForm.setPageState(
                    //       {
                    //         ...myForm.pageState,
                    //         tds_rate:"3"
                    //       }
                    //     )
                    // }

                    let newState = { ...myForm.pageState };

                    if (temp_response.is_more_than_ten == 1) {
                      newState.is_more_than_ten = "1";
                      myForm.setPageState(newState);
                      close();
                      return;
                    }
                    if (temp_response.truck_limit_exceed == 0) {
                      newState.pan_link_status = "0";
                      newState.truck_limit_exceed = "0";
                      myForm.setPageState(newState);
                      close();
                      return;
                    }

                    newState.pan_popup = "1";
                    newState.truck_limit_exceed = "1";
                    newState.pan_link_status = "0";

                    myForm.setPageState(newState);
                    close();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </Popup>

        <div>
          <Popup
            open={showRCDownload}
            modal
            contentStyle={contentStyle}
            closeOnDocumentClick={false}
          >
            <div className="pop-up-container">
              <div className="pop-up-header">
                {
                  `${downloadType} Download`
                }
              </div>

              {downloadType !== "TRANSFERPAPER" && !cameraActive && <button onClick={() => setCameraActive(true)}>Open Camera</button>}

              {downloadType !== "TRANSFERPAPER" && <button onClick={() => { handleImageUpload(downloadType.toLowerCase()); setShowRCDownload(false) }}>Choose File</button>}
              {downloadType == "DECLARATION" && <button onClick={() => {handleImageRemove()}}>Delete Image</button>}
              {downloadType !== "TRANSFERPAPER" && cameraActive && <div className="capture-area">
                <div className="capture-btn">
                  {!photo && <button onClick={capturePhoto} disabled={captureDisabled}>Capture</button>}
                  {photo && <button onClick={regeneratePhoto}>Regenrate</button>}
                  {photo && <button onClick={() => sendPhotoToAPI("rc")}>Submit</button>}
                </div>
                {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
                {photo && <img src={photo} alt="Captured" className="captured-size" />}
              </div>}


              <div className="pop-up-actions">
                {(myForm.pageState.is_rc_image === 1 && downloadType === "RC") && (
                  <button onClick={() => {
                    handleDownloadImage('rc');
                    setShowRCDownload(false);
                  }
                  }>Download RC</button>
                )}
                {(myForm.pageState.is_pan_image === 1 && downloadType === "PAN") && (
                  <button onClick={() => {
                    handleDownloadImage('pan');
                    setShowRCDownload(false)
                  }}
                  >Download PAN</button>
                )}
                {(myForm.pageState.is_declaration_image === 1 && downloadType === "DECLARATION") && (
                  <button onClick={() => {
                    handleDownloadImage('declaration');
                    setShowRCDownload(false)
                  }
                  }>Download Declaration</button>
                )}
                {(myForm.pageState.is_transfer_paper_image === 1 && downloadType === "TRANSFERPAPER") && (
                  <button onClick={() => {
                    handleDownloadImage('transferpaper');
                    setShowRCDownload(false)
                  }
                  }>Download Transferpaper</button>
                )}
                <button
                  className="pop-up-button"
                  onClick={() => {
                    setIsOpenRc() || setIsOpenPan() || setIsOpenDeclaration() || setIsOpenTransfer();
                    stopCamera();
                    setPhoto(null);
                    setCameraActive(false);
                    setShowRCDownload(false)
                    setShowPANDownload(false)
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </Popup>
        </div>
        {/* {
          myForm.setPageState({
            ...myForm.pageState,
            tds_rate:"200"
          })
        } */}
        <Popup
          // trigger={<button className="button"> Open Modal </button>}
          open={myForm.pageState.truck_limit_exceed == "1"}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">Given Pan number exists 10 times, Are you sure want to proceed?</div>
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  onClick={() => {
                    let newState = {
                      pan_popup: "0",
                      // pan_no: "",
                      tds_rate: '',
                      ingore_pan_limit: "1",
                    }
                    if (myForm.pageState.pan_no["3"].toLowerCase() == 'p') {
                      newState.tds_rate = "1"
                    }
                    else {
                      newState.tds_rate = "2"
                    }
                    //// debugger
                    myForm.setPageState({
                      ...myForm.pageState,
                      ...newState,
                      // tds_rate:2
                    });
                    close();
                  }}
                >
                  Yes
                </button>
                <button
                  className="pop-up-button"
                  onClick={() => {
                    let newState = {
                      pan_popup: "0",
                      pan_no: "",
                    }
                    myForm.setPageState({
                      ...myForm.pageState,
                      ...newState,
                    });
                    close();
                  }}
                >
                  No
                </button>
              </div>
            </div>
          )}

        </Popup>

      </div>
      <div>
        <Popup
          // trigger={<button className="button"> Open Modal </button>}
          open={secondOwnerName && showVehiclePopup}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
          {(close) => (
            <div className="pop-up-container">
              <div className="pop-up-header">Vehicle No is already Exists do you want to add new owner name?</div>
              <div className="pop-up-actions">
                <button
                  className="pop-up-button"
                  onClick={() => {
                    //// debugger

                    if (imageShower?.owner_name !== imageShower?.second_owner_name) {
                      myForm.setPageStateByField("second_owner_name", imageShower.owner_name)
                      myForm.setPageStateByField("second_owner_pan_no", imageShower.pan_no)
                      myForm.setPageStateByField("second_owner_entry_date", myForm.pageState.entry_date)
                      myForm.setPageStateByField("owner_name", "")
                      //// debugger
                    }
                    if (vehicleInfo?.owner_name !== vehicleInfo?.second_owner_name) {
                      //// debugger
                      myForm.setPageStateByField("second_owner_name", vehicleInfo.owner_name)
                      myForm.setPageStateByField("second_owner_pan_no", vehicleInfo.pan_no)
                      myForm.setPageStateByField("second_owner_entry_date", myForm.pageState.entry_date)
                      myForm.setPageStateByField("owner_name", "")
                      //// debugger
                    }
                    close();
                  }}
                >
                  Yes
                </button>
                <button
                  className="pop-up-button"
                  onClick={() => {
                    close();
                  }}
                >
                  No
                </button>
              </div>
            </div>
          )}

        </Popup>
      </div>
      <div>
        <Popup
          open={myForm.pageState.save_clicked == true}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
          {(close) => {
            return (
              <div className="pop-up-container">
                <div className="pop-up-header">
                  Enter OTP (Remaining attempts : {myForm.pageState.otp_attempts})
                  <div>
                    <a className="pop-up-close btn" onClick={() => {
                      myForm.setPageState((pageState) => ({
                        ...pageState,
                        "save_clicked": false,
                        "otp": "",
                        "otp_attempts": "3",
                      }))
                      close();
                    }}>
                      &times;
                    </a>
                  </div>
                </div>
                <div className="popup-content">
                  <div className="pop-up-fields">
                    <div className="pop-up-field">
                      <label className="form-label">OTP </label>
                      <input
                        className="form-input"
                        type="text"
                        name="otp"
                        id="otp"
                        value={myForm.pageState.otp}
                        onChange={myForm.handleChange}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            document.getElementById("verified_by").focus();
                          }
                        }}
                        autoFocus={true}
                      />
                    </div>
                    <div className="pop-up-field">
                      <label className="form-label">Verified By </label>
                      <input
                        className="form-input"
                        type="text"
                        name="verified_by"
                        id="verified_by"
                        value={myForm.pageState.verified_by}
                        onChange={myForm.handleChange}
                        onKeyPress={(e) => {
                          if (e.key == "Enter") {
                            document.getElementById("send_otp").focus();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="pop-up-actions">
                  <button
                    className="pop-up-button"
                    id="send_otp"
                    // type="submit"
                    onClick={() => {
                      handleSendOTP();
                      if (myForm.pageState.otp_success == true) {
                        myForm.setPageState((pageState) => ({
                          ...pageState,
                          "save_clicked": false,
                          "otp": "",
                          "otp_attempts": "3",
                          "otp_success": false,
                        }))
                        close();
                      }
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            );
          }}
        </Popup>
      </div>

      {showDeclarationPopup && (
        <Popup
          open={showDeclarationPopup}
          modal
          contentStyle={contentStyle}
          closeOnDocumentClick={false}
        >
          <div className="pop-up-container">
            <div className="pop-up-header">
              Error:- {declarationErrorMessage}
            </div>
            <div className="pop-up-body">
              Do you still want to proceed with the same?
            </div>

            <div className="pop-up-actions">
              <button
                className="pop-up-button"
                onClick={() => {
                  setForceDec(1); 
                  setCheckDate(false);  
                  setShowDeclarationPopup(false); 
                  handleImageUpload("declaration", storedDeclarationImage);
                }}
              >
                Yes
              </button>
              <button
                className="pop-up-button"
                onClick={() => {
                  setSelectedDeclareImage(null);
                  setShowDeclarationPopup(false);
                  
                }}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      )}

      <div className="form-header"> Vehicle </div>
      <div onSubmit={myForm.handleSubmit} className="form" noValidate>
        <div className="form-title">
          <div className="form-row">
            <label className="form-label">Created Date</label>
            <input
              className="form-input-vehicle-date"
              type="date"
              name="entry_date"
              placeholder=""
              disabled="disabled"
              value={myForm.pageState.effective_date}
              onChange={myForm.handleChange}
              // onKeyPress={linkBilty}
              ref={(a) => myForm.storeInputReferenceForSelect(a, "entry_date")}
            // disabled={myForm.pageMode == "view" ? "disabled" : ""}
            />
          </div>

          <div className="form-row">
            <label className="form-last_bilty">Verified By: </label>
            <label className="form-last_bilty">
              {myForm.pageState.verified_by}
            </label>
          </div>
        </div>
        <div className="form-input-content-block-0">
          <div className="form-field-left" style={{ position: 'relative', display: 'flex' }}>
            <span style={{ position: 'absolute', top: 75, right: 0, width: '20%', zIndex: 1 }} >
              {myForm.pageState.pan_adhar_link_status == '1' && (
                <p style={{ zIndex: '1', color: 'green', fontWeight: 'bold', fontSize: '50px' }} >
                  L<br />
                  I<br />
                  N<br />
                  K<br />
                  E<br />
                  D<br />
                </p>
              )}
            </span>
            <div className="flex-wrapper">
              <div className="flex-gap">
                {(isOpenRc || isOpenPan || isOpenDeclaration || isOpenTransfer) && (
                  <Popup
                    open={toggleMode === "rc" || toggleMode === "pan" || toggleMode === "declaration" || toggleMode === "transferpaper"}
                    modal
                    contentStyle={contentStyle}
                    closeOnDocumentClick={false}
                  >
                    <div className="pop-up-container">
                      <div className="pop-up-header">
                        {toggleMode === "rc"
                          ? "Upload RC"
                          : toggleMode === "pan"
                            ? "Upload Pan"
                            : toggleMode === "declaration"
                              ? "Upload Declaration"
                              : "Upload TranferPaper"
                        }
                      </div>
                      {toggleMode === "rc"
                        ? (
                          <>
                            {!cameraActive && <button onClick={() => setCameraActive(true)}>Open Camera</button>}
                            <button onClick={() => handleImageUpload("rc")}>Choose File</button>
                            {cameraActive && <div className="capture-area">
                              <div className="capture-btn">
                                {!photo && <button onClick={capturePhoto} disabled={captureDisabled}>Capture</button>}
                                {photo && <button onClick={regeneratePhoto}>Regenrate</button>}
                                {photo && <button onClick={() => sendPhotoToAPI("rc")}>Submit</button>}
                              </div>
                              {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
                              {photo && <img src={photo} alt="Captured" className="captured-size" />}
                            </div>}
                          </>
                        )
                        : toggleMode === "pan" ?
                          (
                            <>
                              {!cameraActive && <button onClick={() => setCameraActive(true)}>Open Camera</button>}
                              <button onClick={() => handleImageUpload("pan")}>Choose File</button>
                              {cameraActive && <div className="capture-area">
                                <div className="capture-btn">
                                  {!photo && <button onClick={capturePhoto}>Capture</button>}
                                  {photo && <button onClick={regeneratePhoto}>Regenrate</button>}
                                  {photo && <button onClick={() => sendPhotoToAPI("pan")}>Submit</button>}
                                </div>
                                {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
                                {photo && <img src={photo} alt="Captured" className="captured-size" />}
                              </div>}
                            </>
                          )
                          : toggleMode === "declaration" ? (
                            <>
                              {!cameraActive && <button onClick={() => setCameraActive(true)}>Open Camera</button>}
                              <button onClick={() => handleImageUpload("declaration")}>Choose File</button>
                              {cameraActive && <div className="capture-area">
                                <div className="capture-btn">
                                  {!photo && <button onClick={capturePhoto}>Capture</button>}
                                  {photo && <button onClick={regeneratePhoto}>Regenrate</button>}
                                  {photo && <button onClick={() => sendPhotoToAPI("declaration")}>Submit</button>}
                                </div>
                                {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
                                {photo && <img src={photo} alt="Captured" className="captured-size" />}
                              </div>}
                            </>
                          )
                            : (
                              <>
                                {!cameraActive && <button onClick={() => setCameraActive(true)}>Open Camera</button>}
                                <button onClick={() => handleImageUpload("transferpaper")}>Choose File</button>
                                {cameraActive && <div className="capture-area">
                                  <div className="capture-btn">
                                    {!photo && <button onClick={capturePhoto}>Capture</button>}
                                    {photo && <button onClick={regeneratePhoto}>Regenrate</button>}
                                    {photo && <button onClick={() => sendPhotoToAPI("transferpaper")}>Submit</button>}
                                  </div>
                                  {!photo && <video className="camera-size" ref={videoRef} autoPlay />}
                                  {photo && <img src={photo} alt="Captured" className="captured-size" />}
                                </div>}
                              </>
                            )
                      }

                      <div className="pop-up-actions">
                        <button
                          className="pop-up-button"
                          onClick={() => {
                            setIsOpenRc() || setIsOpenPan() || setIsOpenDeclaration() || setIsOpenTransfer();
                            stopCamera();
                            setPhoto(null);
                            setCameraActive(false);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </Popup>
                )}
                <div className="flex-box">
                  {selectedRCImage ? <img onClick={() => handleImagePopUp("RC")} style={{ width: '20%', height: '150px' }} src={selectedRCImage} id="imagePreviewRC" alt="Image Preview" /> : <button onClick={showFlagRc}>Upload RC</button>}
                  {selectedPANImage ? <img onClick={() => handleImagePopUp("PAN")} src={selectedPANImage} id="imagePreviewPAN" alt="Image Preview" style={{ width: '20%', height: '150px' }} /> : <button onClick={showFlagPan}>Upload Pan</button>}
                  {selectedDeclareImage ? <img onClick={() => handleImagePopUp("DECLARATION")} src={selectedDeclareImage} id="imagePreviewDeclare" alt="Image Preview" style={{ width: '20%', height: '150px' }} /> : <button onClick={showFlagDeclaration}>Upload Declare</button>}

                  {/* <button onClick={showFlagDeclaration}>Upload Declaration</button> */}
                  {selectedTransferImage ? <img onClick={() => handleImagePopUp("TRANSFERPAPER")} src={selectedTransferImage} id="imagePreviewDeclare" alt="Image Preview" style={{ width: '20%', height: '150px' }} /> : <button onClick={showFlagTransfer} disabled={jt}>Upload Transferpaper</button>}

                </div>
                <div className="flex-box">
                  {/* {myForm.pageState.is_rc_image === 1 && (
                    <button onClick={() => handleDownloadImage('rc')}>Download RC</button>
                  )}
                  {myForm.pageState.is_pan_image === 1 && (
                    <button onClick={() => handleDownloadImage('pan')}>Download PAN</button>
                  )}
                  {myForm.pageState.is_declaration_image === 1 && (
                    <button onClick={() => handleDownloadImage('declaration')}>Download Declaration</button>
                  )} */}
                  {/* {myForm.pageState.is_transferpaper_image === 1 && (
                    <button onClick={() => handleDownloadImage('transferpaper')}>Download Transferpaper</button>
                  )} */}
                </div>
              </div>
              <div>
                <button
                  id="send_button"
                  onClick={(e) => {
                    if (sessionObject.sessionVariables.role_id == "1" || myForm.pageState.otp_success || !handleOtp) {
                      // debugger
                      if(myForm.pageState.owner_name === ""){
                        alert("Please Enter Owner Name")
                      }else{
                        myForm.handleSubmit(e);
                      }
                    }
                    else {
                      // debugger
                      if(myForm.pageState.owner_name === ""){
                          alert("Please Enter Owner Name")
                      }
                      const url = SERVER_URL + "/token_verification/generate?"
                        + `vehicle_no=${myForm.pageState.vehicle_no}`
                        + `&name=${myForm.pageState.owner_name}`
                        + `&pan_no=${myForm.pageState.pan_no}`
                        + `&tds_rate=${myForm.pageState.tds_rate}`
                        + `&declaration=${myForm.pageState.policy_no}`;
                      fetch(url, {
                        method: "POST",
                        headers: {
                          Accept: "application/json",
                          "Content-Type": "application/json",
                        },
                      });

                      

                      myForm.setPageStateByField("save_clicked", true)
                    }
                  }}
                  disabled={myForm.pageState.vehicle_no === "" || myForm.pageState.pan_no === ""  || saveDisable || myForm.pageState.pan_adhar_link_status == 3|| myForm.pageState.pan_adhar_link_status == 0}
                  type="button"
                  className="btn btn-primary"
                  ref={(a) => myForm.storeInputReferenceForSelect(a, "save_button")}
                >
                  Save
                </button>
              </div>
            </div>
            <div style={{ flex: '1' }}>
              <FormColumn
                groupInfo={groupInfo}
                groupName="group-1"
                checkDisabledCondition={checkDisabledCondition}
                checkVisibilityCondition={checkVisibilityCondition}
                myFormObj={myForm}
                getAdditionalInfoForSuggestionFetch={getAdditionalInfoForSuggestionFetch}
              />
            </div>


          </div>
          <div className="form-field-right">
            <FormColumn
              groupInfo={groupInfo}
              groupName="group-2"
              checkDisabledCondition={checkDisabledCondition}
              checkVisibilityCondition={checkVisibilityCondition}
              myFormObj={myForm}
              getAdditionalInfoForSuggestionFetch={getAdditionalInfoForSuggestionFetch}
            />
          </div>
        </div>

        <div className="form-footer">
          <button
            onClick={() => {
              console.log("Values", myForm.pageState);
            }}
            type="button"
            className="btn btn-primary"
          >
            Log
          </button>
          <button
            id="send_button"
            onClick={async (e) => {
              e.preventDefault();

              myForm.pageState.force_dec = forceDec; 

              if (sessionObject.sessionVariables.role_id == "1" || myForm.pageState.otp_success || !handleOtp) {
                if (myForm.pageState.owner_name === "") {
                  alert("Please Enter Owner Name");
                } else {
                  myForm.handleSubmit(e);
                }
              } else {
                const url = `${SERVER_URL}/token_verification/generate?`
                  + `vehicle_no=${myForm.pageState.vehicle_no}`
                  + `&name=${myForm.pageState.owner_name}`
                  + `&pan_no=${myForm.pageState.pan_no}`
                  + `&tds_rate=${myForm.pageState.tds_rate}`
                  + `&declaration=${myForm.pageState.policy_no}`;

                try {
                  const response = await fetch(url, {
                    method: "POST",
                    headers: {
                      Accept: "application/json",
                      "Content-Type": "application/json",
                    },
                  });

                  if (!response.ok) {
                    throw new Error(`Token verification failed: ${response.statusText}`);
                  }

                  myForm.setPageStateByField("save_clicked", true);
                } catch (error) {
                  console.error("Error during token verification:", error);
                  alert("Failed to verify token. Please try again.");
                  return;
                }
              }

              if (uploadRcAgain) {
                const rcUrl = `${SERVER_URL}/vehicle/upload_rc_again?vehicle_no=${myForm.pageState.vehicle_no}&type=rc_book&client_name=rcc`;

                const formData = new FormData();
                formData.append('file', rcAgain);

                const response = await fetch(rcUrl, {
                  method: "POST",
                  body: formData,
                });

                if (!response.ok) {
                  console.error("Failed to upload RC:", response.statusText);
                  alert("Failed to upload RC. Please try again.");
                  return;
                }

                myForm.setPageStateByField("rc_upload_success", true);
              }

              if (uploadPanAgain) {
                const panUrl = `${SERVER_URL}/vehicle/upload_pan_again?pan_no=${myForm.pageState.pan_no}&type=pan&client_name=rcc`;

                const formData = new FormData();
                formData.append('file', panAgain);

                const response = await fetch(panUrl, {
                  method: "POST",
                  body: formData,
                });

                if (!response.ok) {
                  console.error("Failed to upload Pan:", response.statusText);
                  alert("Failed to upload Pan. Please try again.");
                  return;
                }

                myForm.setPageStateByField("pan_upload_success", true);
              }
            }}
            disabled={
              myForm.pageState.vehicle_no === "" ||
              myForm.pageState.pan_no === "" ||
              saveDisable ||
              myForm.pageState.pan_adhar_link_status == 3 ||
              myForm.pageState.pan_adhar_link_status == 0
            }
            type="button"
            className="btn btn-primary"
            ref={(a) => myForm.storeInputReferenceForSelect(a, "save_button")}
          >
            Save
          </button>

          {/* <div className="status">{myForm.renderSubmitApiResponseStatus()}</div> */}
        </div>
      </div>
    </div>
  );
};

export default VehicleForm;
