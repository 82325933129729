
import React from "react";
import { SERVER_URL } from "../config/config.js";
import "./biltyReport.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReportTable from "./ReportTable";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import ReportExcel from "./ReportExcel.js";
import { useLocation } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
let ex_data = [];
function LoginReport({ sessionObject }) {
    const branchId = sessionObject.sessionVariables.branch_id;
    const columns = React.useMemo(
        () => [
            {
                Header: 'User Name',
                accessor: 'user_name',
                canFilter: true,
                width: '80px',
                minWidth: '80px'
            },
            {
                Header: 'IP Address',
                accessor: 'ip_address',
                canFilter: true,
                width: '350px',
                minWidth: '350px'
            },
            {
                Header: 'Login Date',
                accessor: 'login_date',
                canFilter: true,
                width: '100px',
                minWidth: '80px'
            },
            {
                Header: 'Login Time',
                accessor: 'login_time',
                canFilter: true,
                width: '100px',
                minWidth: '90px'
            },
        ],
        []
    );
    const [data, setData] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [pageCount, setPageCount] = React.useState(0);
    const fetchIdRef = React.useRef(0);
    const download_ref = React.useRef(null);
    const sortIdRef = React.useRef(0);
    const [dateState, setDateState] = React.useState({
        date_from: new Date(),
        date_to: new Date(),
    });
    const [finalInput, setFinalInput] = React.useState({});
    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    };
    let query = useQuery();
    useEffect(() => {
        const reportType = query.get("report_type");
        setDateState({
            ...dateState,
            ["report_type"]: reportType,
        })
    }, []);
    const fetchData = React.useCallback(
        async ({ pageSize, pageIndex, sortBy, customFilters, dateObject }) => {
            const fetchId = ++fetchIdRef.current;
            for (let key in customFilters) {
                if (customFilters[key].toLowerCase() === "none") {
                    customFilters[key] = null;
                }
            }
            setLoading(true);
    
            if (fetchId === fetchIdRef.current) {
                let newObject = { ...customFilters };
    
                delete newObject.companyId;
                delete newObject.fyear;
    
                const dateFrom = new Date(dateObject.date_from);
                dateFrom.setHours(0, 0, 0, 1);
    
                const dateTo = new Date(dateObject.date_to);
                dateTo.setHours(23, 59, 59, 999);
    
                const dateFromUTC = new Date(dateFrom.getTime() - dateFrom.getTimezoneOffset() * 60000).toISOString();
                const dateToUTC = new Date(dateTo.getTime() - dateTo.getTimezoneOffset() * 60000).toISOString();
    
                let inputData = {
                    paginate: { number_of_rows: pageSize, page_number: pageIndex + 1 },
                    sort_fields: sortBy,
                    filter_fields: newObject,
                    date_dict: {
                        date_from: dateFromUTC,
                        date_to: dateToUTC,
                    },
                };
                setFinalInput(inputData);
                let response = await fetch(SERVER_URL + "/report/get_login_history", {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(inputData),
                });
                let resp = await response.json();
                if (resp["data"] && "total_rows" in resp) {
                    setData(resp["data"]);
                    setPageCount(Math.ceil(resp["total_rows"] / pageSize));
                }
                setLoading(false);
            }
        },
        []
    );
    return (
        <div className="page-marfatiya-wise">
            <div className="report-bilty">
                <div className="form-header">Login Report</div>
                <div className="report-bilty-table-container">
                    <div className="report-field-row ">
                        <div>
                            From Date:{" "}
                            <DatePicker
                                dateFormat="dd-MM-yyy"
                                selected={dateState.date_from}
                                onChange={(date) =>
                                    setDateState({
                                        ...dateState,
                                        ["date_from"]: date,
                                    })
                                }
                            />
                        </div>
                        <div>
                            To Date:{" "}
                            <DatePicker
                                dateFormat="dd-MM-yyy"
                                selected={dateState.date_to}
                                onChange={(date) =>
                                    setDateState({
                                        ...dateState,
                                        ["date_to"]: date,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <ReportTable
                        columns={columns}
                        data={data}
                        fetchData={fetchData}
                        dateObject={dateState}
                        loading={loading}
                        pageCount={pageCount}
                        canView={true}
                        title = "loginReport"
                    />
                    {/* <div className="form-footer">
                        <button style={{ display: "none" }}>
                            <ReactHTMLTableToExcel
                                id="test-table-xls-button"
                                className="download-table-xls-button"
                                table="pod_statement_report_excel"
                                filename="tablexls"
                                sheet="tablexls"
                                buttonText="Download as XLS"
                                ref={(a) => (download_ref.current = a)}
                            />
                        </button>
                        <button
                            className="download-table-xls-button"
                            onClick={async () => {
                                setLoading(true);
                                let dataToSend = {
                                    date_dict: finalInput.date_dict,
                                    filter_fields: finalInput.filter_fields,
                                };
                                console.log("data to send in EXCEL:", dataToSend);
                                let resp = await fetch(SERVER_URL + "/report/pod_statement", {
                                    method: "POST",
                                    headers: {
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(dataToSend),
                                });
                                let response = await resp.json();
                                if (response["data"] && "total_rows" in response) {
                                    console.log(response["data"], "fsdfafsfds");
                                    ex_data = response["data"];
                                }
                                setLoading(false);
                                download_ref.current.handleDownload();
                            }}
                        >
                            Download as XLS
                        </button>
                        {ReportExcel.pod_statement_excel(
                            { columns },
                            { ex_data },
                            { fetchData },
                            { dateState },
                            { loading },
                            { pageCount },
                            { dateState }
                        )}
                    </div> */}
                </div>
            </div>
        </div>
    );
}
export default LoginReport;