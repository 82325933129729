import React from "react";
import LoginReport from "../LoginReport";
export default function ReportLogin({ sessionObject }) {
  if (!sessionObject.sessionVariables["modules"].includes("report-login")) {
    return;
  }
  return (
    <div className="page-bilty">
      <LoginReport sessionObject={sessionObject} />
    </div>
  );
}
